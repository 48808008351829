import { AppRouteProps } from "@mzara/component";
import React from "react";
import { HomeRoutes } from "./home/HomeRoutes";
import { OutsourcingRoutes } from "./outsourcing/OutsourcingRoutes";
import { PapercutRoutes } from "./papercut/PapercutRoutes";
import { LaserLabsRoutes } from "./laser-labs/LaserLabsRoutes";
import { ContactRoutes } from "./contact/ContactRoutes";

const MainLayout = React.lazy(() => import('../components/layout/MainLayout'))

export const Routes: Array<AppRouteProps> = [
    {
        path: ':lang',
        title: 'IsaWeb.Title',
        description: 'IsaWeb.Description',
        keywords: 'IsaWeb.Keywords',
        element: <MainLayout />,
        children: [
            ...OutsourcingRoutes,
            ...PapercutRoutes,
            ...LaserLabsRoutes,
            ...ContactRoutes,
            ...HomeRoutes,
        ]
    }
]