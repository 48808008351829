import { AppRouteProps } from "@mzara/component";
import React from "react";

const Home = React.lazy(() => import('./containers/Home'))

export const HomeRoutes: Array<AppRouteProps> = [
    {
        path: '',
        title: 'IsaWeb.Home.Title',
        element: <Home />,
    },
    {
        path: ':swiperId',
        title: 'IsaWeb.Home.Title',
        element: <Home />,
    }
]
