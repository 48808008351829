import { AppRouteProps } from "@mzara/component";
import React from "react";

const Papercut = React.lazy(() => import("./containers/Papercut"));

export const PapercutRoutes: Array<AppRouteProps> = [
    {
        path: "papercut",
        title: 'IsaWeb.Papercut.Title',
        description: "IsaWeb.Papercut.Description",
        element: <Papercut />,
    }, {
        path: "papercut/:swiperId",
        element: <Papercut />,
    }
];