import { AppRouteProps } from "@mzara/component";
import React from "react";

const Contact = React.lazy(() => import("../../components/ContactSection"))

export const ContactRoutes: Array<AppRouteProps> = [
    {
        path: 'contact',
        title: 'IsaWeb.Contact.Title',
        description: 'IsaWeb.Contact.Contact.Description',
        element: <Contact active />,
    },
]
