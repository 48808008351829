import React from 'react';
import { AppRoot } from '@mzara/component';
import { Routes } from './pages/Routes'

function App() {

    return (
        <AppRoot routes={Routes} />
    );
}

export default App;
