import { AppRouteProps } from "@mzara/component";
import React from "react";

const Outsourcing = React.lazy(() => import("./containers/Outsourcing"));

export const OutsourcingRoutes: Array<AppRouteProps> = [
  {
    path: "outsourcing",
    title: 'IsaWeb.Outsourcing.Title',
    description: "IsaWeb.Home.Activity.Outsourcing.Description",
    element: <Outsourcing />,
  },
  {
    path: "outsourcing/:swiperId",
    title: 'IsaWeb.Outsourcing.Title',
    element: <Outsourcing />,
  }
];