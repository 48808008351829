import { AppRouteProps } from "@mzara/component";
import React from "react";

const LaserLabs = React.lazy(() => import("./containers/LaserLabs"));
const LaserLabsModalGallery = React.lazy(() => import("./containers/LaserLabsModalGallery"));

export const LaserLabsRoutes: Array<AppRouteProps> = [
    {
        path: "laser",
        title: 'IsaWeb.LaserLabs.Title',
        description: 'IsaWeb.Home.Activity.LaserLabs.Description',
        element: <LaserLabs />,
    }, {
        path: "laser/:swiperId",
        title: 'IsaWeb.LaserLabs.Title',
        description: 'IsaWeb.Home.Activity.LaserLabs.Description',
        element: <LaserLabs />,
        children: [
            {
                path: 'modal',
                title: 'IsaWeb.LaserLabs.Gallery.Title',
                element: <LaserLabsModalGallery />
            }, {
                path: 'modal/:imgUrl',
                title: 'IsaWeb.LaserLabs.Gallery.Title',
                element: <LaserLabsModalGallery />
            }
        ]
    }, {
        title: 'IsaWeb.LaserLabs.Title',
        path: "laser/:swiperId/:categoryId",
        element: <LaserLabs />,
    }
];